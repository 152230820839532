@import '../../styles/variables';
Button {
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  touch-action: manipulation;
  position: relative;
  padding: 1em 0.8em;
  // line-height: 1.28rem;
  color: white;
  border-radius: 3px;
  background: $primary-color;
}
