@import '../../styles/breakpoint';
@import '../../styles/variables';
.Layout {
  // background: $gradient;
  // overflow: hidden;
  // background-repeat: repeat;
  display: block;

  &Content {
    display: block;
    padding: 0em 8em;
    justify-content: center;
    align-items: center;
    @include sm {
      padding: 0;
    }
  }
  &Bg {
    background: $gradient;
    text-align: center;

    svg {
      width: 8em;
      height: 8em;
    }
  }
}
