@import '../../styles/variables';
@import '../../styles/breakpoint';

.Register {
  font-size: 1rem;
  padding: 0 3em;
  background: $white;
  border-radius: 5px;
  font-family: Jost;
  @include sm {
    padding: 0 1em;
  }
  h1,
  h4 {
    text-align: center;
  }
  h1 {
    font-size: 1.5em;
  }
  h4 {
    padding-bottom: 1.25em;
    font-size: 1em;
  }

  &Form {
    button {
      margin-top: 1em;
    }
    .submit {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1.5em;
      @include sm {
        grid-column-gap: 1em;
      }
    }
  }
  .Completed {
    text-align: center;
    svg {
      height: 3em;
      width: 3em;
    }
  }
}
